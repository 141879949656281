.img-gallery{
    display: block;
    position: relative;
}

.img-gallery-btn{
    position: absolute;
    top: 0;
    background: rgba(0,0,0,0.3);
    color: white;
    padding-top: 30%;
    z-index: 999;
    height: 100%;
}

.img-gallery-btn.left-btn{
    left: 0;
}

.img-gallery-btn.right-btn{
    right: 0;
}

.img-caption{
    text-align: center;
    font-style: italic;
}

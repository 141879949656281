.the-notebook {
    margin-top: 20px;
    padding: 0;
}

.the-notebook-header{
    background-image: url('../../../images/notebook_header_repeat.png');
    background-repeat: repeat-x;
    height: 72px;
}

.the-notebook-footer{
    background-image: url('../../../images/notebook_footer.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    height: 59px;
}
.the-notebook-content{
    background-color: #1d3771;
    display: block;
    padding: 10px;
    margin: 20px 30px;
    box-shadow: 3px 3px 8px rgba(0,0,0,0.3);
}

.the-notebook-wrapper{
    background-color: #8cc540;
    margin-top: 3px;
    padding: 0px;
    padding-top: 10px;
}

.the-notebook-menu{
}

.the-notebook-footer{
    text-align: center;
    background-image: none;
    height: 59px;
}

.the-notebook-footer .ui.buttons.basic .ui.button{
    color: white !Important;
    background-color: none;
}
.the-notebook-footer .ui.buttons.basic .ui.button:hover{
    color: black !Important;
    background-color: none;
}

.the-notebook-wrapper:hover .the-notebook-footer{
    background-image: url('../../../images/notebook_footer.png');
    background-position: right bottom;
    background-repeat: no-repeat;
}

.the-notebook-wrapper h2{
    text-align: center;
    color: white;
    text-shadow: 3px 3px 8px rgba(0,0,0,0.3);
}

.the-notebook-wrapper .img-caption{
    color: white;
}

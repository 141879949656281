@import-normalize;

body{
 background: white;
}
.gradient-fill{
    background-image: linear-gradient(to right top, #1e283c, #1b2334, #181e2d, #151925, #12141e) !Important;
}


.body-text{
    padding: 0;
    margin: 0;
    font-size: 1.2em;
    line-height: 1.4em;
    text-align: justify;
}

.ui.segment.post-container{
    margin-bottom: 50px;
    background-color: white;
}
.ui.grid.post-detail{
    margin-top: 30px;
  }

.ui.grid.blog-content{
  margin-top: 50px;
}

@media screen and (min-width: 820px) {

  body{
   /*background: rgb(24,30,45);*/
    /*background: linear-gradient(90deg, rgba(24,30,45,0.10) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(24,30,45,0.10) 100%);*/
  }
  .ui.grid.post-detail{
    margin-top: 0px;
  }

  .ui.grid.blog-content{
    margin-top: 0px;
  }
}
